import styled, { css, keyframes } from "styled-components";
import { device } from "../../assets/styles/breakpoints";

export const Container = styled.div`
  position: fixed;
  top: 85%;
  left: 80%;

  @media ${device.tablet} {
    left: 85%;
  }

  .chat-icon {
    cursor: pointer;
    font-size: 3rem;
    color: var(--secondary);
    position: relative;
    transition: color 0.3s ease;
    filter: drop-shadow(2px 2px 3px grey);

    &:hover {
      animation: vibrate 0.3s linear infinite;
      color: var(--primary);
    }
  }

  .chat-dots::before {
    content: "Chat"; /* Default content */
    transition: content 0.3s ease;
  }

  .chat-icon:hover + .chat-dots::before {
    content: "•••";
    color: var(--secondary);
    font-size: 1.5rem;
  }

  .chat-dots {
    position: absolute;
    top: 43%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1rem;
    color: var(--primary);
    pointer-events: none;
  }

  @keyframes vibrate {
    0% {
      transform: translate(0);
    }
    20% {
      transform: translate(-2px, 2px);
    }
    40% {
      transform: translate(2px, -2px);
    }
    60% {
      transform: translate(-2px, -2px);
    }
    80% {
      transform: translate(2px, 2px);
    }
    100% {
      transform: translate(0);
    }
  }
`;

export const ChatText = styled.div`
  position: absolute;
  bottom: 110%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--primary);
  color: var(--secondary);
  padding: 0.5rem;
  border-radius: 5px;
  font-size: 0.8rem;
  opacity: 0;
  width: 0;
  height: 0;
  white-space: nowrap;
  overflow: hidden;
  transition: opacity 0.5s ease-in-out;
  animation: expandBox 20s ease-in-out infinite; /* Total cycle time is now 20 seconds */
  // text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.tablet} {
    font-size: 1rem;
    animation: expandBoxTablet 20s ease-in-out infinite;
  }

  /* Default keyframes */
  @keyframes expandBox {
    0%,
    50% {
      width: 0;
      height: 0;
      opacity: 0;
    }
    55% {
      width: 80px;
      height: 30px;
      opacity: 1;
    }
    75% {
      width: 80px;
      height: 30px;
      opacity: 1; /* Stays visible for 5 seconds */
    }
    80%,
    100% {
      width: 80px;
      height: 30px;
      opacity: 0; /* Disappears and waits for the next cycle */
    }
  }

  /* Keyframes for tablet size */
  @keyframes expandBoxTablet {
    0%,
    50% {
      width: 0;
      height: 0;
      opacity: 0;
    }
    55% {
      width: 120px;
      height: 30px;
      opacity: 1;
    }
    75% {
      width: 120px;
      height: 30px;
      opacity: 1; /* Stays visible for 5 seconds */
    }
    80%,
    100% {
      width: 120px;
      height: 30px;
      opacity: 0; /* Disappears and waits for the next cycle */
    }
  }
`;

export const ChatBoxContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 130%;
  left: -200%;
  background-color: var(--primary);
  color: var(--secondary);
  // padding: 1rem;
  border-radius: 10px;
  width: 300px;
  height: 400px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-size: 1rem;
  opacity: 0;
  animation: openChat 0.5s ease forwards;

  @keyframes openChat {
    0% {
      opacity: 0;
      transform: scale(0.9) translateX(-50%);
    }
    100% {
      opacity: 1;
      transform: scale(1) translateX(-50%);
    }
  }

  .header {
    background-color: var(--background-color);
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

  .clean-icon {
    position: absolute;
    left: 52%;
    cursor: pointer;
    color: var(--primary);
  }
  .clean-icon:hover {
    transform: scale(1.2);
  }

  .close-icon {
    cursor: pointer;
    font-size: 2rem;
  }
  .close-icon:hover {
    transform: scale(1.2);
  }
`;

export const ChatTextarea = styled.textarea`
  width: 90%;
  min-height: 11%;
  max-height: 11%;
  padding: 0.7rem 0.7rem;
  margin: 0.5rem 0;
  border: 1px solid var(--secondary);
  border-radius: 25px;
  font-size: 1rem;
  font-weight: 300;
  outline: none;
  overflow-y: hidden;
  resize: none; // Prevent manual resizing
  // overflow: hidden;  // Hide overflow while resizing
  // max-height: 150px;  // Add a max height to avoid excessive growth
  box-sizing: border-box;
  word-wrap: break-word;
`;

export const MessageList = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  height: 74%;
`;

export const Message = styled.div<{ $is_user: boolean }>`
  background-color: ${({ $is_user }) =>
    $is_user ? "var(--secondary)" : "#e0e0e0"};
  color: ${({ $is_user }) => ($is_user ? "white" : "black")};
  padding: 0.5rem 1rem;
  border-radius: 10px;
  margin-bottom: 0.5rem;
  max-width: 70%;
  word-wrap: break-word;

  ${({ $is_user }) =>
    $is_user
      ? css`
          align-self: flex-end; /* User messages on the right */
        `
      : css`
          align-self: flex-start; /* Bot messages on the left */
        `}
`;

export const Timestamp = styled.span`
  font-size: 0.75rem;
  color: #888;
  margin-bottom: 6px;
`;

// สร้าง keyframes สำหรับ animation ของการพิมพ์
const typingAnimation = keyframes`
  0%, 80%, 100% {
    transform: translateY(0);
    opacity: 0.3;
  }
  40% {
    transform: translateY(-8px);
    opacity: 1;
  }
`;

export const TypingIndicatorWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const TypingDot = styled.div`
  width: 8px;
  height: 8px;
  background-color: #4a90e2;
  border-radius: 50%;
  opacity: 0.6;
  animation: ${typingAnimation} 1s infinite ease-in-out;

  &:nth-child(1) {
    animation-delay: 0s;
  }

  &:nth-child(2) {
    animation-delay: 0.15s;
  }

  &:nth-child(3) {
    animation-delay: 0.3s;
  }
`;

//text aniamtion

const float = keyframes`
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-3px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(0);
  }
`;

export const FloatingText = styled.div`
  margin-top: 1rem;
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
  color: #4a90e2;
  animation: ${float} 5s cubic-bezier(0.08, 0.91, 0.92, 0.09) infinite;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  letter-spacing: 1.5px;
  transition: color 0.3s ease;

  &:hover {
    color: #ff6b6b;
  }
`;
