import styled from "styled-components";
import { device } from "../../assets/styles/breakpoints";
export const FooterContainer = styled.div`
  /* border-top: 1px solid #4c5759; */
  height: var(--footer-height);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--secondary);
  background-color: var(--background-color);
  font-weight: 300;
  color: #4c5759;
  padding: 0 1.5rem;
  @media ${device.tablet} {
    padding: 0;
  }
`;

export const P = styled.p`
  font-size: 0.8rem;
  @media ${device.tablet} {
    font-size: 1rem;
  }
`;
