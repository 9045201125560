import React from "react";
import { MainLayout } from "../../layouts";
import ProfileComponent from "../../components/Profile";

const Home = () => {
  return (
    <MainLayout>
      <div style={{ marginTop: "2rem" }}>
        <ProfileComponent />
      </div>
    </MainLayout>
  );
};

export default Home;
