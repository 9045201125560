import styled from "styled-components";
import { device } from "../../assets/styles/breakpoints";
export const Body = styled.div`
  width: 100vw;
  height: auto;
  // border: 1px solid green;
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  color: var(--secondary);
`;

export const Container = styled.div`
  // border: 1px solid red;
  width: 70vw;
  margin-bottom: 3rem;
  @media ${device.laptop} {
    width: 60vw;
  }
  @media ${device.laptopL} {
    width: 50vw;
  }
`;

export const HeadText = styled.p`
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
`;

export const ItemContainer = styled.div`
  // border: 1px solid yellow;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 0;
  row-gap: 40px;

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 40px;
  }
`;

export const Card = styled.div`
  min-height: 400px;
  max-height: 500px;
  max-width: 70vw;
  // border: 1px solid green;
  display: flex;
  flex-direction: column;
  color: var(--primary);
  .image {
    width: 100%;
    height: 150px;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }
  .image:hover {
    transform: scale(1.025);
  }

  .name {
    margin-top: 10px;
    text-align: center;
  }

  .description {
    width: 100%;
    text-align: center;
    font-weight: 200;
    word-wrap: break-word;
  }

  @media ${device.desktop} {
    max-width: 24.5vw;
    .image {
      height: 300px;
    }
  }

  @media ${device.laptopL} {
    max-width: 23.5vw;
    min-height: 200px;
    max-height: 400px;
    .image {
      height: 200px;
    }
  }

  @media ${device.laptop} {
    max-width: 28vw;
    min-height: 200px;
    max-height: 400px;
  }
  @media ${device.tablet} {
    max-width: 32vw;
    min-height: 200px;
    max-height: 400px;
  }
`;
