import styled from "styled-components";
import { device } from "../../assets/styles/breakpoints";

export const ProfileContainer = styled.div`
  color: var(--primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  .profile-head {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: "M PLUS Rounded 1c", sans-serif;
  }
  .real-name {
    font-size: 1.3rem;
  }
`;

export const ProfileImg = styled.img`
  border-radius: 50%;
  width: 17rem;
  height: 17rem;
`;

export const Paragraph = styled.p`
  font-size: 1rem;
  font-weight: bold;
  @media ${device.mobileS} {
    font-size: 1.2rem;
  }
  @media ${device.mobileL} {
    font-size: 1.5rem;
  }
`;

export const Name = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
  @media ${device.mobileS} {
    font-size: 1.8rem;
  }
  @media ${device.mobileL} {
    font-size: 3rem;
  }
`;

export const DowloadBtn = styled.a`
  color: #06b6d4;
  border: 1px solid #06b6d4;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  &:hover {
    color: #057083;
    border-color: #057083;
  }
`;

export const ContentBox = styled.div`
  min-width: 80vw;
  max-width: 80vw;
  margin-top: 1rem;
  @media ${device.tablet} {
    min-width: 50vw;
    max-width: 50vw;
  }
`;

export const Card = styled.div`
  color: var(--primary);
  display: flex;
  flex-direction: column;
  padding: 0rem 1rem;
  margin: 1rem 0;
  width: 100%;
  @media ${device.tablet} {
    width: auto;
  }
`;

export const HeaderText = styled.span`
  font-family: "M PLUS Rounded 1c", sans-serif;
  text-decoration: underline;
  text-decoration-color: #525252;
  text-decoration-thickness: 4px;
  text-underline-offset: 6px;
  font-size: 1.3rem;
  font-weight: 700;
  color: var(--secondary);
  margin-top: 20px;
  @media ${device.mobileS} {
    font-size: 1.7rem;
  }
`;

export const SubParagraph = styled.p`
  font-weight: 400;
  font-size: 1rem;
  @media ${device.mobileL} {
    font-size: 1.2rem;
  }
`;

export const FollowItem = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  margin-bottom: 0.8rem;
  font-size: 0.9rem;
  padding: 0rem 0.5rem;
  height: 2rem;
  cursor: pointer;

  &:hover {
    color: #000;
    background: #9cacab;
    border: 1px solid #9cacab;
    border-radius: 4px;
  }

  .text {
    cursor: auto;
  }

  .follow-icon {
    font-size: 1.2rem;
    margin-right: 10px;
    height: 100%;
  }
  .follow-text {
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

export const SubParagraphHeader = styled.p`
  font-size: 1.5rem;
  font-weight: 600;
  text-decoration: underline;
`;

export const UlBox = styled.ul`
  list-style: disc inside;
`;

export const LI = styled.li``;
