import Dotfiles from "../../assets/images/dotfiles-project/fish.png";
const projects = [
  {
    img: Dotfiles,
    name: "My Dotfiles",
    description: "This is my dotfiles config and workflow to coding.",
  },
];

export default projects;
