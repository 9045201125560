import React, { useRef, useEffect, useState } from "react";
import { Body, Loader } from "./Loading.styles";

const Loading: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isFullWidth, setIsFullWidth] = useState(false);

  useEffect(() => {
    const checkParentWidth = () => {
      if (containerRef.current && containerRef.current.parentElement) {
        const parentWidth = containerRef.current.parentElement.offsetWidth;
        const windowWidth = window.innerWidth;

        // ตรวจสอบว่า parent element มีขนาดเต็มหน้าจอหรือไม่
        setIsFullWidth(parentWidth >= windowWidth - 1); // ลบเล็กน้อยเพื่อหลีกเลี่ยงการ rounding error
      } else {
        setIsFullWidth(false); // ถ้าไม่มี parent ให้ใช้ขนาดเต็มจอ
      }
    };

    // เรียกใช้เมื่อ component mount
    checkParentWidth();

    // ตรวจสอบขนาดใหม่เมื่อ resize
    window.addEventListener("resize", checkParentWidth);
    return () => {
      window.removeEventListener("resize", checkParentWidth);
    };
  }, []);

  return (
    <Body $is_full_width={isFullWidth} ref={containerRef}>
      <Loader />
    </Body>
  );
};

export default Loading;

// https://css-loaders.com/
