import React, {
  useState,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
} from "react";
import { THEMES } from "./Theme.config";
import { Theme, ThemeType } from "./Theme.model";

const PRIMARY_THEME = "dark";
const SECONDARY_THEME = "light";

// Context
interface ThemeContextProps {
  themeType: ThemeType;
  theme: Theme;
  setCurrentTheme: Dispatch<SetStateAction<ThemeType>>;
}

export const ThemeContext = createContext<ThemeContextProps>({
  themeType: PRIMARY_THEME,
  theme: THEMES[PRIMARY_THEME],
} as ThemeContextProps);

// Provider
type Props = {
  children: React.ReactNode;
};
export const ThemeProvider = ({ children }: Props) => {
  const [currentTheme, setCurrentTheme] =
    useState<ThemeType>(checkLocalTheme());

  return (
    <ThemeContext.Provider
      value={{
        themeType: currentTheme,
        theme: THEMES[currentTheme],
        setCurrentTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);

const checkLocalTheme = (): ThemeType => {
  const localTheme = localStorage.getItem("theme");
  const isLightBrowser =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: light)").matches;

  if (localTheme) {
    return localTheme as ThemeType;
  }
  return isLightBrowser ? SECONDARY_THEME : PRIMARY_THEME;
};
