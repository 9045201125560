import React from "react";
import { MainLayout } from "../../layouts";
import CardPreviews from "../../components/CardPreviews";
import products from "../../services/datas/products";
import ComingSoon from "../../components/ComingSoon";
const Products = () => {
  return (
    <MainLayout>
      {/* <CardPreviews objects={products} /> */}
      <ComingSoon targetDate="2024-11-15T23:59:59" description="products" />
    </MainLayout>
  );
};

export default Products;
