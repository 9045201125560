import styled, { keyframes } from "styled-components";
import { BsFillMoonStarsFill, BsFillSunFill, BsYoutube } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import { device } from "../../assets/styles/breakpoints";

// Keyframes
const moonStarAnimation = keyframes`
  0% {transform: rotate(-180deg);}
  100% {transform: rotate(0deg);}

`;

const sunAnimation = keyframes`
  0% {transform: rotate(0deg);}
  100% {transform: rotate(90deg);}
`;

// Elements
export const NavbarContainer = styled.div<{ $isscrolling: boolean }>`
  /* Base styles for small/mobile devices (default) */
  height: var(--navbar-height);
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: var(--navbar-background-color);
  color: var(--primary);
  position: sticky;
  position: -webkit-sticky; /* safari */
  top: 0;
  backdrop-filter: blur(10px); /* Apply the blur effect to the background */
  -webkit-backdrop-filter: blur(10px); /* Safari support */
  z-index: 9999;
  /* Enhancements for larger devices */
  @media ${device.mobileL} {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  @media ${device.laptop} {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  @media ${device.laptopL} {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  @media ${device.desktop} {
    padding-left: 20rem;
    padding-right: 20rem;
  }
`;

export const Nav = styled.nav`
  height: 100%;
  display: flex;
  justify-content: space-between;
`;

export const LogoText = styled(NavLink)`
  /* Base styles for small/mobile devices */
  font-size: 1.2rem;
  line-height: 1.75rem;
  color: var(--logo-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;

  /* Enhancements for larger devices */
  @media ${device.mobileM} {
    font-size: 1.5rem;
  }

  @media ${device.mobileL} {
    font-size: 1.8rem;
  }

  @media ${device.laptop} {
    font-size: 1.75rem;
  }
`;

export const MenuUl = styled.ul<{ $show: boolean }>`
  /* Base styles for mobile devices */
  z-index: 9999;
  overflow: hidden;
  position: fixed;
  left: 0;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  background-color: var(--background-color);
  justify-content: center;
  transform: ${({ $show }) => ($show ? "translateY(0%)" : "translateY(-100%)")};
  transition: transform 0.5s;

  /* Enhancements for larger devices */
  @media ${device.laptop} {
    background-color: var(--navbar-background-color);
    position: static;
    width: auto;
    height: auto;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transform: none; /* Reset the transform for laptop view */
  }
`;

export const ChangeThemeContainer = styled.div`
  right: 5rem;
  top: 3rem;
  position: absolute;
  @media ${device.tablet} {
    right: 6.5rem;
  }
  @media ${device.laptop} {
    position: static;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const MoonStarIcon = styled(BsFillMoonStarsFill)<{
  $ischangetheme: boolean;
}>`
  margin-left: 1.5rem;
  cursor: pointer;
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
  color: var(--change-theme-icon-color);
  animation-name: ${moonStarAnimation};
  animation-duration: ${({ $ischangetheme }) =>
    $ischangetheme ? "0.8s" : "0s"};
  transition: transform 0.1s ease-in;
  &:hover {
    transform: scale(1.1);
  }
`;

export const SunIcon = styled(BsFillSunFill)<{
  $ischangetheme: boolean;
}>`
  margin-left: 1.5rem;
  cursor: pointer;
  font-size: 1.5rem;
  line-height: 2rem;
  color: var(--change-theme-icon-color);
  animation-name: ${sunAnimation};
  animation-duration: ${({ $ischangetheme }) =>
    $ischangetheme ? "0.8s" : "0s"};
  transition: transform 0.1s ease-in;
  &:hover {
    transform: scale(1.1);
  }
`;

export const MenuLi = styled.li`
  /* Base styles for mobile devices */
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
  gap: 0.5rem;
  padding-top: 13rem;
  padding-bottom: 13rem;

  /* Enhancements for larger devices */
  @media ${device.laptop} {
    justify-content: center;
    flex-direction: row;
    padding: 0;
    height: auto;
    gap: 0;
  }
`;

export const MenuButton = styled(NavLink)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-size: 1rem;
  margin-right: 0;
  margin-left: 0;
  border-radius: 0.375rem; /* 6px */
  padding-top: 0.5rem; /* 8px */
  padding-bottom: 0.5rem; /* 8px */
  padding-left: 1rem; /* 16px */
  padding-right: 1rem; /* 16px */
  color: var(--primary);
  transition: transform 70ms ease-in;

  &:hover,
  &.active {
    transform: scale(1.1);
  }
  &.active {
    --tw-gradient-from: #06b6d4;
    --tw-gradient-to: #14b8a6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
    color: var(--white);

    &:hover {
      transform: scale(1.2);
    }
  }

  @media ${device.laptop} {
    display: block;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`;

export const IconContainer = styled.a`
  margin-left: 1.5rem;
  border: 3px solid #4c5759;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 1.5rem;
  top: 5.5rem;
  @media ${device.tablet} {
    right: 3.1rem;
    top: 6rem;
  }

  @media ${device.laptop} {
    position: static;
  }
`;

export const YoutubeIcon = styled(BsYoutube)`
  cursor: pointer;
  font-size: 1.5rem;
  line-height: 2rem;
  color: #06b6d4;
  transition: transform 0.1s ease-in;
  &:hover {
    transform: scale(1.1);
  }
`;

//Social share button

//Hamburger
const moveUpThenDown = keyframes`
  0% {
    top: 0;
  }
  50% {
    top: -27px;
  }
  100% {
    top: -14px;
  }
`;
const shakeWhileMovingUp = keyframes`
  0% {
    transform: rotateZ(0);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  50% {
    transform: rotateZ(0deg);
  }
  75% {
    transform: rotateZ(10deg);
  }
  100% {
    transform: rotateZ(0);
  }
`;
const shakeWhileMovingDown = keyframes`
  0% {
    transform: rotateZ(0);
  }
  80% {
    transform: rotateZ(3deg);
  }
  90% {
    transform: rotateZ(-3deg);
  }
  100% {
    transform: rotateZ(0);
  }
`;
export const HamWrapCover = styled.div`
  z-index: 10000;
  position: fixed;
  width: 30px;
  right: 2rem;
  top: 2.5rem;
  display: block;
  @media ${device.tablet} {
    width: 39px;
    right: 3rem;
  }

  @media ${device.laptop} {
    display: none;
  }
`;

export const HamMenuButton = styled.div`
  width: 30px;
  overflow: hidden;
  @media ${device.laptop} {
    width: 39px;
  }
`;

export const MenuTextBar = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: var(--primary);
  top: 24px;
  border-radius: 4px;
  &:before {
    content: "MENU";
    position: absolute;
    top: 15px;
    right: 0;
    left: 0;
    color: var(--primary);
    font-size: 10px;
    font-weight: bold;
    font-family: "M PLUS Rounded 1c", sans-serif;
    text-align: center;
  }
`;

export const MenuLabel = styled.label`
  position: relative;
  display: block;
  height: 29px;
  cursor: pointer;
  &:before,
  &:after {
    border-radius: 4px;
    position: absolute;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: var(--primary);
    content: "";
    transition: 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55) left;
  }
  &:before {
    top: 0;
  }
  &:after {
    top: 12px;
  }
`;

export const MenuCheckBox = styled.input`
  display: none;

  &:checked + ${MenuLabel}:before {
    left: -39px;
  }
  &:checked + ${MenuLabel}:after {
    left: 39px;
  }
  &:checked + ${MenuLabel} ${MenuTextBar}:before {
    animation:
      ${moveUpThenDown} 0.8s ease 0.2s forwards,
      ${shakeWhileMovingUp} 0.8s ease 0.2s forwards,
      ${shakeWhileMovingDown} 0.2s ease 0.8s forwards;
  }
`;
