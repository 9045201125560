import React, { ReactNode, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Body from "./Body";
import Footer from "./Footer";
import { useTheme } from "../contexts/Theme/Theme.context";
import ChatBox from "../components/ChatBox";
import FootprintsLoading from "../components/FootprintsLoading";
import { ToastContainer } from "react-toastify";
import { AnimatePresence, motion } from "framer-motion";
import "react-toastify/dist/ReactToastify.css";
import "../assets/styles/Global.styles.css";
import { size } from "../assets/styles/breakpoints";
interface IndexProps {
  children?: ReactNode;
  isLoading?: boolean;
}

export const MainLayout: React.FC<IndexProps> = ({
  children,
  isLoading = false,
}) => {
  const { theme } = useTheme();

  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
  const [isExit, setIsExit] = useState<boolean>(false);
  const navigate = useNavigate();
  // กำหนดค่าของ transition และ animation variants
  const fadeInVariants = {
    initial: { y: 20, opacity: 0 },
    animate: { y: 0, opacity: 1 },
  };

  const exitVariants = {
    initial: { y: 0, opacity: 1 },
    animate: { y: 20, opacity: 0 },
  };

  const pageTransition = {
    duration: 0.5,
    ease: "easeInOut",
  };

  const handleNavigate = (path: string) => {
    if (location.pathname !== path) {
      setIsExit(true);
      setTimeout(() => {
        navigate(path); // เปลี่ยนเส้นทางหลังจากแอนิเมชันเสร็จสิ้น
        setIsExit(false);
      }, 500);
    }
  };

  // ตรวจสอบขนาดหน้าจอเมื่อเปลี่ยนแปลง
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup เพื่อป้องกันการเกิด memory leak
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div style={{ ...(theme as React.CSSProperties) }}>
        {isLoading ? (
          <FootprintsLoading />
        ) : (
          <>
            <Navbar specialNavigate={handleNavigate} />
            <Body>
              <AnimatePresence mode="wait">
                <motion.div
                  initial="initial"
                  animate="animate"
                  variants={
                    isExit && screenWidth > size.tablet
                      ? exitVariants
                      : fadeInVariants
                  }
                  transition={pageTransition}
                >
                  {children}
                </motion.div>
              </AnimatePresence>
            </Body>
            <ChatBox />
            <Footer />
          </>
        )}
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </>
  );
};

export const SecondLayout = () => {
  return <></>;
};
