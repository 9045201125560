import React from "react";
import { MainLayout } from "../../layouts";
import {
  Body,
  Container,
  HtmlPreviewCard,
  TemplateImg,
} from "./Template.styles";
import ComingSoon from "../../components/ComingSoon";
import Web1 from "../../assets/images/web1.png";

const Templates = () => {
  const launchDate = "2024-11-15T23:59:59";
  return (
    <MainLayout>
      {/* <Container> */}
      {/*   {[...Array(10)].map((e, i) => { */}
      {/*     return ( */}
      {/*       <HtmlPreviewCard key={i}> */}
      {/*         <TemplateImg src={Web1} /> */}
      {/*       </HtmlPreviewCard> */}
      {/*     ); */}
      {/*   })} */}
      {/* </Container> */}
      <Body>
        <ComingSoon targetDate={launchDate} description="templates" />
      </Body>
    </MainLayout>
  );
};

export default Templates;
