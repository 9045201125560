import React from "react";
import ReactDOM from "react-dom/client";
import Main from "./routes";
import { ThemeProvider } from "./contexts/Theme/Theme.context";

const rootElement = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(rootElement);

const render = (Component: React.FC) => {
  root.render(
    <ThemeProvider>
      <Component />
    </ThemeProvider>,
  );
};

render(Main);

// Enable Hot Module Replacement (HMR)
if (module.hot) {
  module.hot.accept("./routes", () => {
    const NextMain = require("./routes").default;
    render(NextMain);
  });
}
