import styled from "styled-components";

export const Body = styled.div<{ $is_full_width: boolean }>`
  width: ${({ $is_full_width }) => ($is_full_width ? "100vw" : "100%")};
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ $is_full_width }) =>
    $is_full_width ? " var(--background-color)" : "inherit"};
`;

export const Loader = styled.div`
  width: calc(6 * 30px);
  height: 50px;
  display: grid;
  color: #8d7958;
  filter: drop-shadow(30px 25px 0 currentColor)
    drop-shadow(60px 0 0 currentColor) drop-shadow(120px 0 0 currentColor);
  clip-path: inset(0 100% 0 0);
  animation: l14 2s infinite steps(7);

  &::before,
  &::after {
    content: "";
    width: 24px;
    grid-area: 1 / 1;
    height: 9px;
    background:
      radial-gradient(farthest-side, currentColor 90%, #0000) left / 10px 9px,
      conic-gradient(
          from -106deg at right,
          #0000,
          currentColor 2deg 29deg,
          #0000 33deg
        )
        right / 17px 11px;
    background-repeat: no-repeat;
    transform: rotate(7deg);
    transform-origin: 5px 50%;
  }

  &::after {
    margin-top: 12px;
    transform: rotate(-7deg);
  }

  @keyframes l14 {
    100% {
      clip-path: inset(0 -30px 0 0);
    }
  }
`;
