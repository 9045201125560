import React from "react";
import { Box, Overlay, Button, ButtonContainer } from "./ClearChatModal.styles";
interface ClearChatModalProps {
  showModal: () => void;
  handleNewChat: () => void;
}
const ClearChatModal: React.FC<ClearChatModalProps> = ({
  showModal,
  handleNewChat,
}) => {
  const handleClearChat = () => {
    localStorage.removeItem("chatRoomId");
    showModal();
    handleNewChat();
  };
  return (
    <Overlay>
      <Box>
        <h2>Clear Chat</h2>
        <p>Are you sure you want to clear the chat history?</p>
        <ButtonContainer>
          <Button onClick={handleClearChat}>Confirm</Button>
          <Button onClick={showModal}>Cancel</Button>
        </ButtonContainer>
      </Box>
    </Overlay>
  );
};
export default ClearChatModal;
