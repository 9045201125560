import React, { useEffect, useState } from "react";
import { MainLayout } from "../../layouts";
import CardPreviews from "../../components/CardPreviews";
import projects from "../../services/datas/projects";

const Projects: React.FC = () => {
  return (
    <MainLayout>
      <CardPreviews headText="Projects" objects={projects} />
    </MainLayout>
  );
};

export default Projects;
