import React, { useState, useRef, useEffect } from "react";
import { HiChatBubbleOvalLeft } from "react-icons/hi2";
import { MdCleaningServices } from "react-icons/md";
import { IoIosCloseCircle } from "react-icons/io";
import ClearChatModal from "./ClearChatModal";
import FootPrintsLoading from "../FootprintsLoading";
import {
  Container,
  ChatText,
  ChatBoxContainer,
  ChatTextarea,
  Message,
  MessageList,
  Timestamp,
  TypingDot,
  TypingIndicatorWrapper,
  FloatingText,
} from "./Chatbox.styles";

interface ChatMessage {
  sender: string;
  message: string;
  timestamp: string;
}

// Type สำหรับผลลัพธ์ของ groupMessagesByTimestamp
type GroupedMessages = {
  [timestamp: string]: ChatMessage[];
};

// ฟังก์ชันสำหรับจัดรูปแบบเวลาเป็นสั้นๆ
const formatTimestamp = (timestamp: string): string => {
  const date = new Date(timestamp);
  const options: Intl.DateTimeFormatOptions = {
    year: "2-digit",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  return date.toLocaleDateString(undefined, options);
};

const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // เดือนเริ่มจาก 0
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

// ฟังก์ชันสำหรับจัดกลุ่มข้อความโดยใช้ timestamp ถึงนาที
const groupMessagesByTimestamp = (messages: ChatMessage[]): GroupedMessages => {
  return messages.reduce((acc: GroupedMessages, message: ChatMessage) => {
    // ตัดวินาทีออก เหลือแค่ 'ปี-เดือน-วัน ชั่วโมง:นาที'
    const timestampWithoutSeconds = message.timestamp.slice(0, 16); // 'YYYY-MM-DD HH:MM'
    if (!acc[timestampWithoutSeconds]) {
      acc[timestampWithoutSeconds] = [];
    }
    acc[timestampWithoutSeconds].push(message);
    return acc;
  }, {});
};

const ChatBox: React.FC = () => {
  const [isBotTyping, setIsBotTyping] = useState(false);
  const [isChatting, setIsChatting] = useState(false);
  const [isShowClearChat, setIsShowClearChat] = useState(false);
  const [isConnectSocketLoading, setIsConnectSocketLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState<ChatMessage[]>([]); // Array to store the sent messages
  const [groupedMessages, setGroupedMessages] = useState<GroupedMessages>({});
  const [roomName, setRoomName] = useState<string | null>(""); // ค่า room_name เริ่มต้น
  const [chatSocket, setChatSocket] = useState<WebSocket | null>(null); // เก็บ WebSocket instance

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const messageListRef = useRef<HTMLDivElement>(null);

  const handleChatSocket = () => {
    setIsConnectSocketLoading(true);
    let socket: WebSocket | null = null;

    if (isChatting) {
      const socket_host = process.env.DEFAULT_SOCKET_HOST;
      socket = new WebSocket(`${socket_host}`);

      socket.onopen = () => {
        setIsConnectSocketLoading(false);

        // console.log("WebSocket connection established");

        const chat_room_id = localStorage.getItem("chatRoomId");
        if (chat_room_id) {
          //Check room in database
          socket!.send(
            JSON.stringify({ type: "join_room", room_name: chat_room_id }),
          );
        } else {
          //Create chat room
          socket!.send(JSON.stringify({ type: "create_room" }));
        }
      };

      socket.onmessage = (e) => {
        const data = JSON.parse(e.data);

        if (data.type == "join_room_status") {
          if (data.status == "failed") {
            // console.log(
            //   "Join room failed: Doesn't have a room_name in data base",
            // );
            // console.log("Creating new room");
            socket!.send(JSON.stringify({ type: "create_room" }));
          } else if (data.status == "succeed") {
            // console.log("Join room succeed");
            setRoomName(data.room_name);
          }
        } else if (data.type == "create_room_status") {
          if (data.status == "succeed") {
            localStorage.setItem("chatRoomId", data.room_name);
            setRoomName(data.room_name);
          }
        } else if (data.type == "bot_message") {
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              sender: data.sender,
              message: data.message,
              timestamp: data.timestamp,
            },
          ]);
        } else if (data.type == "chat_history") {
          // console.log(data.messages);
          setMessages(data.messages);
        } else if (data.type === "bot_typing" && data.status === "typing") {
          setIsBotTyping(true); // แสดงสถานะการพิมพ์ของบอท
        } else if (data.type === "bot_typing" && data.status === "done") {
          setIsBotTyping(false); // ซ่อนสถานะการพิมพ์เมื่อบอทหยุดพิมพ์
        }
      };

      socket.onclose = () => {
        // console.log("WebSocket connection closed");
      };

      // บันทึก WebSocket instance
      setChatSocket(socket);
    }

    // ปิด WebSocket เมื่อหยุดแชท
    return () => {
      if (socket) {
        socket.close();
      }
      setChatSocket(null);
    };
  };

  const handleIconClick = () => {
    setIsChatting(!isChatting);
    localStorage.setItem("isChatting", JSON.stringify(!isChatting));
  };

  const handleNewChat = () => {
    setMessages([]);
    return handleChatSocket();
  };

  const handleShowClearChat = () => {
    setIsShowClearChat(!isShowClearChat);
  };

  const handleSendMessage = () => {
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        sender: "Anonymous",
        message: message,
        timestamp: formatDate(new Date()),
      },
    ]);
    if (chatSocket && chatSocket.readyState === WebSocket.OPEN) {
      chatSocket.send(
        JSON.stringify({ type: "user_message", message: message }),
      );
      setMessage("");
    }

    // Reset the textarea height to its initial value
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
    }
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
    e.target.style.height = "auto"; // Reset height
    e.target.style.height = `${e.target.scrollHeight}px`; // Adjust height based on content
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevents adding a new line
      handleSendMessage(); // Call send message on Enter
    }
  };

  useEffect(() => {
    setGroupedMessages(groupMessagesByTimestamp(messages));
  }, [messages]); // Run effect whenever the `messages` array changes

  // Automatically scroll to bottom when a new message is added
  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [groupedMessages, isBotTyping]);

  useEffect(() => {
    return handleChatSocket();
  }, [isChatting]);

  useEffect(() => {
    const isChattingLocal = localStorage.getItem("isChatting");
    if (isChattingLocal) {
      setIsChatting(JSON.parse(isChattingLocal));
    }
  }, []);

  return (
    <Container>
      <HiChatBubbleOvalLeft className="chat-icon" onClick={handleIconClick} />
      <span className="chat-dots"></span>
      {!isChatting && <ChatText>Chat with me</ChatText>}
      {isChatting && (
        <ChatBoxContainer>
          <div className="header">
            @demonsjostle (AI)
            <MdCleaningServices
              className="clean-icon"
              onClick={handleShowClearChat}
            />
            <IoIosCloseCircle
              className="close-icon"
              onClick={handleIconClick}
            />
          </div>

          <MessageList ref={messageListRef}>
            {isConnectSocketLoading ? (
              <React.Fragment>
                <FootPrintsLoading />
              </React.Fragment>
            ) : (
              <React.Fragment>
                {messages.length !== 0 ? (
                  <React.Fragment>
                    {Object.keys(groupedMessages).map((timestamp) => (
                      <React.Fragment key={timestamp}>
                        <Timestamp>{formatTimestamp(timestamp)}</Timestamp>
                        {groupedMessages[timestamp].map((msg, index) => (
                          <Message key={index} $is_user={msg.sender !== "GPT"}>
                            {msg.message}
                          </Message>
                        ))}
                      </React.Fragment>
                    ))}
                    {isBotTyping && (
                      <React.Fragment>
                        <TypingIndicatorWrapper>
                          <TypingDot />
                          <TypingDot />
                          <TypingDot />
                        </TypingIndicatorWrapper>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <FloatingText>📜 มีอะไรให้ฉันช่วยไหม ?</FloatingText>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </MessageList>
          <ChatTextarea
            ref={textareaRef}
            value={message}
            placeholder="Type a message..."
            onChange={handleTextareaChange}
            onKeyDown={handleKeyDown} // Handle onEnter
            rows={1} // Set the initial number of rows
          />

          {/* Clear Chat Modal  */}
          {isShowClearChat && (
            <ClearChatModal
              showModal={handleShowClearChat}
              handleNewChat={handleNewChat}
            />
          )}
        </ChatBoxContainer>
      )}
    </Container>
  );
};

export default ChatBox;
