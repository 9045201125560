import React, { useState } from "react";
import {
  LogoText,
  MenuLi,
  MenuUl,
  MoonStarIcon,
  Nav,
  NavbarContainer,
  SunIcon,
  MenuButton,
  YoutubeIcon,
  IconContainer,
  MenuCheckBox,
  HamMenuButton,
  MenuLabel,
  MenuTextBar,
  HamWrapCover,
  ChangeThemeContainer,
} from "./Navbar.styles";

import { useTheme } from "../../contexts/Theme/Theme.context";

interface NavbarProps {
  specialNavigate: (path: string) => void;
}
const Navbar: React.FC<NavbarProps> = ({ specialNavigate }) => {
  const { themeType, setCurrentTheme } = useTheme();
  const [isChangeTheme, setIsChangeTheme] = useState(false);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const handleTheme = (): void => {
    if (themeType === "dark") {
      setCurrentTheme("light");
    } else if (themeType === "light") {
      setCurrentTheme("dark");
    }
    setIsChangeTheme(true);
  };

  React.useEffect(() => {
    localStorage.setItem("theme", themeType);
  });

  React.useEffect(() => {
    document.body.style.overflow = isNavbarOpen ? "hidden" : "unset";
  }, [isNavbarOpen]);

  React.useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > lastScrollTop) {
        // User is scrolling down
        setIsScrolling(true);
      } else {
        // User is scrolling up
        setIsScrolling(false);
      }

      // Update last scroll position
      setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]); // Dependency array includes lastScrollTop

  return (
    <>
      <NavbarContainer $isscrolling={isScrolling}>
        <Nav>
          {/* <LogoText to="/">Demons&nbsp;&nbsp;Jostle</LogoText> */}
          <LogoText to="/">@demonsjostle</LogoText>
          <MenuUl $show={isNavbarOpen}>
            <MenuLi>
              {specialNavigate ? (
                <>
                  <MenuButton
                    as="div"
                    onClick={() => specialNavigate("/")}
                    className={location.pathname === "/" ? "active" : ""}
                  >
                    Home
                  </MenuButton>
                  <MenuButton
                    as="div"
                    onClick={() => specialNavigate("/projects")}
                    className={
                      location.pathname === "/projects" ? "active" : ""
                    }
                  >
                    Projects
                  </MenuButton>
                  <MenuButton
                    as="div"
                    onClick={() => specialNavigate("/products")}
                    className={
                      location.pathname === "/products" ? "active" : ""
                    }
                  >
                    Products
                  </MenuButton>
                  <MenuButton
                    as="div"
                    onClick={() => specialNavigate("/templates")}
                    className={
                      location.pathname === "/templates" ? "active" : ""
                    }
                  >
                    Templates
                  </MenuButton>
                </>
              ) : (
                <>
                  <MenuButton to="/">Home</MenuButton>
                  <MenuButton to="/projects">Projects</MenuButton>
                  <MenuButton to="/products">Products</MenuButton>
                  <MenuButton to="/templates">Templates</MenuButton>
                </>
              )}
            </MenuLi>
            <ChangeThemeContainer onClick={handleTheme}>
              {themeType === "dark" ? (
                <MoonStarIcon $ischangetheme={isChangeTheme} />
              ) : (
                <SunIcon $ischangetheme={isChangeTheme} />
              )}
            </ChangeThemeContainer>
            <IconContainer
              href="https://youtube.com/@demonsjostle"
              target="_blank"
              rel="noreferrer"
            >
              <YoutubeIcon />
            </IconContainer>
          </MenuUl>
          <Hamburger
            onChange={(e) => {
              const checked = e.target.checked;
              if (checked) {
                setIsNavbarOpen(true);
              } else {
                setIsNavbarOpen(false);
              }
            }}
          />
        </Nav>
      </NavbarContainer>
    </>
  );
};

interface HamburgerProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Hamburger = ({ onChange }: HamburgerProps) => {
  return (
    <HamWrapCover>
      <HamMenuButton>
        <MenuCheckBox type="checkbox" id="menu_checkbox" onChange={onChange} />
        <MenuLabel htmlFor="menu_checkbox">
          <MenuTextBar></MenuTextBar>
        </MenuLabel>
      </HamMenuButton>
    </HamWrapCover>
  );
};

export default Navbar;
