import React, { useEffect, useState } from "react";
import {
  ComingSoonContainer,
  TimeBox,
  Title,
  Subtitle,
  Countdown,
  Time,
  Label,
} from "./styles";

interface ComingSoonProps {
  targetDate: string; // Pass the target date as a string in ISO format (e.g., "2024-12-31T23:59:59")
  description: string;
}
const ComingSoon: React.FC<ComingSoonProps> = React.memo(
  ({ targetDate, description = "website" }) => {
    const [timeLeft, setTimeLeft] = useState({
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    });

    useEffect(() => {
      const targetTime = new Date(targetDate).getTime();

      const countdownTimer = setInterval(() => {
        const now = new Date().getTime();
        const distance = targetTime - now;

        if (distance < 0) {
          clearInterval(countdownTimer);
          return;
        }

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setTimeLeft({ days, hours, minutes, seconds });
      }, 1000);

      return () => clearInterval(countdownTimer);
    }, [targetDate]);

    return (
      <ComingSoonContainer>
        <Title>Coming Soon</Title>
        <Subtitle>Our {description} is launching soon. Stay tuned!</Subtitle>
        <Countdown>
          <TimeBox>
            <Time>{timeLeft.days}</Time>
            <Label>Days</Label>
          </TimeBox>
          <TimeBox>
            <Time>{timeLeft.hours}</Time>
            <Label>Hours</Label>
          </TimeBox>
          <TimeBox>
            <Time>{timeLeft.minutes}</Time>
            <Label>Minutes</Label>
          </TimeBox>
          <TimeBox>
            <Time>{timeLeft.seconds}</Time>
            <Label>Seconds</Label>
          </TimeBox>
        </Countdown>
      </ComingSoonContainer>
    );
  },
);

export default ComingSoon;
