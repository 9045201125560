import styled, { keyframes } from "styled-components";

// Keyframes with bounce effect
const bounceIn = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5) rotate(-10deg);
  }
  60% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.05) rotate(3deg);
  }
  80% {
    transform: translate(-50%, -50%) scale(0.98) rotate(-2deg);
  }
  100% {
    transform: translate(-50%, -50%) scale(1) rotate(0);
  }
`;

// Modal box styling with mobile-first approach
export const Box = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 400px;
  animation: ${bounceIn} 0.6s ease-out;
  z-index: 1000;
  opacity: 1;
`;

// Modal background overlay
export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Button styling with mobile-first approach
export const Button = styled.button`
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25);
  transition: all 0.3s ease;
  margin-right: 10px;

  &:hover {
    background: linear-gradient(135deg, #a777e3, #6e8efb);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
    transform: translateY(-2px);
  }

  &:focus {
    outline: none;
  }

  &:active {
    transform: scale(0.98);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  }
`;

// Container for buttons (Flexbox to align buttons horizontally)
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  flex-direction: row;
`;
