import React, { useEffect, useState } from "react";
import { Body, Container, HeadText, ItemContainer, Card } from "./styles";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// Define the structure of the project array for TypeScript support
interface Object {
  name: string;
  description: string;
  img: string;
}

// The component now accepts `projects` as a prop
interface ObjectListProps {
  objects: Object[];
  headText: string;
}

const CardPreviews: React.FC<ObjectListProps> = ({ headText, objects }) => {
  return (
    <Body>
      <Container>
        <HeadText>{headText}</HeadText>
        {objects && (
          <ItemContainer>
            {objects.map((object, index) => (
              <Card key={index}>
                <LazyLoadImage
                  alt={object.name}
                  className="image"
                  effect="blur"
                  placeholderSrc={object.img}
                  src={object.img}
                />
                <div className="name">{object.name}</div>
                <div className="description">{object.description}</div>
              </Card>
            ))}
          </ItemContainer>
        )}
      </Container>
    </Body>
  );
};

export default CardPreviews;
