export enum Color {
  VIOLET = "#9E25FC",
  DARK_GRAY = "#111827",
  LIGHT_GRAY = "#F4F4F4",
  RED = "#FF0000",
  GREEN = "#00FF00",
  BLUE = "#0000FF",
  YELLOW = "#FFFF00",
  CYAN = "#00FFFF",
  MAGENTA = "#FF00FF",
  BLACK = "#000000",
  WHITE = "#FFFFFF",
  GRAY = "#808080",
  ORANGE = "#FFA500",
  PURPLE = "#800080",
  BROWN = "#A52A2A",
  BRIGHT_CERULEAN = "#06B6D4",
  TOPAZ = "#14b8a6",
}
