import React from "react";
import { FooterContainer, P } from "./Footer.styles";

const Footer = () => {
  return (
    <FooterContainer>
      <P>© {new Date().getFullYear()} @demonsjostle. All Rights Reserved.</P>
    </FooterContainer>
  );
};

export default Footer;
