import React, { ReactNode } from "react";
interface IndexProps {
  children?: ReactNode;
}
import { BodyContainer } from "./Body.styles";

const Body: React.FC<IndexProps> = ({ children }) => {
  return <BodyContainer>{children}</BodyContainer>;
};

export default Body;
