import styled from "styled-components";

export const Container = styled.div`
  border: 1px solid green;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  padding: 20px;
  grid-gap: 40px;
  place-items: center center;
`;

export const HtmlPreviewCard = styled.div`
  border-radius: 20px;
  border: 1px solid red;
  width: 20rem;
  height: 20rem;
  position: relative;
`;

export const TemplateImg = styled.img`
  border: 1px solid green;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  border-radius: 20px;
`;

export const Body = styled.div`
  color: #fff;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  width: 100%;
  min-height: var(--body-height);
`;
