import { Color } from "./color.model";
import { ThemeType, Theme } from "./Theme.model";

export const THEMES: Record<ThemeType, Theme> = {
  light: {
    "--primary": Color.DARK_GRAY,
    "--secondary": Color.BRIGHT_CERULEAN,
    "--navbar-background-color": Color.LIGHT_GRAY,
    "--background-color": Color.WHITE,
    "--logo-color": Color.DARK_GRAY,
    "--change-theme-icon-color": Color.ORANGE,
    "--white": Color.WHITE,
  },
  dark: {
    "--primary": Color.WHITE,
    "--secondary": Color.BRIGHT_CERULEAN,
    "--navbar-background-color": Color.DARK_GRAY,
    "--background-color": Color.DARK_GRAY,
    "--logo-color": Color.WHITE,
    "--change-theme-icon-color": Color.WHITE,
    "--white": Color.WHITE,
  },
};
