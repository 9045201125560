import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const backgroundMove = keyframes`
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
`;

export const ComingSoonContainer = styled.div`
  // border: 1px solid red;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: var(--body-height);
  padding: 20px;
  color: white;
  text-align: center;
  // animation: ${backgroundMove} 10s infinite alternate ease-in-out;
`;

export const Title = styled.h1`
  font-size: 2rem;
  letter-spacing: 0.1em;
  margin-bottom: 10px;
  // animation: ${fadeIn} 2s ease-in-out;

  @media (min-width: 768px) {
    font-size: 4rem;
  }
`;

export const Subtitle = styled.p`
  font-size: 1rem;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const Countdown = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  // animation: ${fadeIn} 3s ease-in-out;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    gap: 20px;
  }
`;

export const TimeBox = styled.div`
  background-color: rgba(255, 255, 255, 0.2);
  padding: 10px;
  border-radius: 10px;
  font-size: 0.8rem;

  @media (min-width: 768px) {
    padding: 20px;
    font-size: 1.2rem;
  }
`;

export const Time = styled.span`
  display: block;
  font-size: 1.5rem;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

export const Label = styled.span`
  display: block;
`;
