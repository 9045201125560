import React, { useEffect, useState } from "react";
import {
  Card,
  DowloadBtn,
  HeaderText,
  ProfileContainer,
  LI,
  Name,
  Paragraph,
  ProfileImg,
  SubParagraph,
  SubParagraphHeader,
  UlBox,
  ContentBox,
  FollowItem,
} from "./Profile.styles";

// Image
import ProfileImage from "../../assets/images/avatar1.jpg";

import { FaGithub } from "react-icons/fa";
import { MdMarkEmailUnread } from "react-icons/md";

import { TbBrandYoutube } from "react-icons/tb";
import { FaInstagram } from "react-icons/fa";

import { FaThreads } from "react-icons/fa6";

import { FaTiktok } from "react-icons/fa6";

import { toast } from "react-toastify";

const Profile = () => {
  const [age, setAge] = useState(0);

  const [copied, setCopied] = useState(false);

  const calculate_age = (dob: Date) => {
    const EPOCH = new Date(0);
    const EPOCH_YEAR = EPOCH.getUTCFullYear();
    var diff_ms = Date.now() - dob.getTime();
    var age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - EPOCH_YEAR);
  };

  const handleCopy = (textToCopy: string) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 1000); // Reset after 1 second
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    } else {
      // ใช้ fallback หาก Clipboard API ไม่รองรับ
      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        setCopied(true);
        setTimeout(() => setCopied(false), 1000); // Reset after 1 second
      } catch (err) {
        console.error("Fallback: Failed to copy", err);
      }
      document.body.removeChild(textArea);
    }
  };

  const handleOpenNewTab = (url: string) => {
    window.open(url, "_blank");
  };

  //set age
  useEffect(() => {
    const currentAge = calculate_age(new Date(1998, 12, 19));
    setAge(currentAge);
  });

  useEffect(() => {
    if (copied === true) {
      toast('copied 🦄 "demons.jostle@gmail.com"');
    }
  }, [copied]);

  return (
    <ProfileContainer>
      <div className="profile-head">
        <Paragraph>Hello, I'm</Paragraph>
        <Name>Demons Jostle</Name>
        {/* <span className="real-name">(Kiatisak Somkhunnguen)</span> */}
        <Paragraph>Full Stack Developer & Youtuber</Paragraph>
      </div>
      {/* <DowloadBtn href="../../assets/demonsjostle_resume.pdf" download> */}
      {/*   Download Portfolio */}
      {/* </DowloadBtn> */}
      {/* <ProfileImg src={ProfileImage} /> */}

      <ContentBox>
        <HeaderText>#Bio</HeaderText>
        <Card>
          <SubParagraph>Kiatisak Somkhunnguen - Mon</SubParagraph>
          <SubParagraph>1998 Born in Langsuan, Thailand.</SubParagraph>
          <SubParagraph>
            2024 Completed the Computer Engineering at Prince of Songkhla
            University
          </SubParagraph>
          <SubParagraph>2023 to present Working as a freelancer</SubParagraph>
        </Card>
      </ContentBox>

      <ContentBox>
        <HeaderText>#Work</HeaderText>
        <Card>
          <SubParagraph>
            I'm a freelance and a full-stack developer based in Thailand. I like
            to make awesome web application.
          </SubParagraph>
          {/* <UlBox> */}
          {/*   <li> */}
          {/*     2022 - make a line chatbot for uploading file and image to cloud */}
          {/*     with 66transfer */}
          {/*   </li> */}
          {/*   <li> */}
          {/*     2022 - make a line chatbot for ordering drinks in coffee shop */}
          {/*   </li> */}
          {/*   <li>2022 - make a line chatbot for educational guidance</li> */}
          {/*   <li>2022 - make a line chatbot for educational guidance</li> */}
          {/*   <li> */}
          {/*     2023 - make a google chrome extension for shorten url or link */}
          {/*   </li> */}
          {/*   <li> */}
          {/*     2023 - make a Shopify app and theme extension for shipping online */}
          {/*   </li> */}
          {/* </UlBox> */}
        </Card>
      </ContentBox>

      <ContentBox>
        <HeaderText>#I love ❤️‍🔥</HeaderText>
        <Card>
          <SubParagraph>
            Tennis, Weight training, Swimming, Anime, AI, Robot, League of
            Legends, Hunt: Showdown
          </SubParagraph>
        </Card>
      </ContentBox>

      <ContentBox>
        <HeaderText>#Follow Me</HeaderText>

        <Card>
          <FollowItem onClick={() => handleCopy("demons.jostle@gmail.com")}>
            <MdMarkEmailUnread className="follow-icon" />
            <span className="follow-text">demons.jostle@gmail.com</span>
          </FollowItem>

          <FollowItem
            onClick={() => {
              handleOpenNewTab("https://github.com/demonsjostle");
            }}
          >
            <FaGithub className="follow-icon" />
            <span className="follow-text">Github</span>
          </FollowItem>

          <FollowItem
            onClick={() => {
              handleOpenNewTab("https://youtube.com/@demonsjostle");
            }}
          >
            <TbBrandYoutube className="follow-icon" />
            <span className="follow-text">Youtube</span>
          </FollowItem>

          <FollowItem
            onClick={() =>
              handleOpenNewTab("https://www.instagram.com/demonsjostle")
            }
          >
            <FaInstagram className="follow-icon" />
            <span className="follow-text">Instagram</span>
          </FollowItem>

          <FollowItem
            onClick={() =>
              handleOpenNewTab("https://www.threads.net/@demonsjostle")
            }
          >
            <FaThreads className="follow-icon" />
            <span className="follow-text">Threads</span>
          </FollowItem>

          <FollowItem
            onClick={() =>
              handleOpenNewTab("https://www.tiktok.com/@demonsjostle")
            }
          >
            <FaTiktok className="follow-icon" />
            <span className="follow-text">Tiktok</span>
          </FollowItem>
        </Card>
      </ContentBox>
    </ProfileContainer>
  );
};

export default Profile;
